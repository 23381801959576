.container {

  .button {
    cursor: pointer;
    border: 1px solid var(--borderColor);
    height: 40px;
    width: 50px;
    box-sizing: border-box;
  }

  .selected {
    background: var(--m-Sienna);
    border: none;

    &:hover {
      background: var(--m-Sienna10);
    }
  }

  .disabled {
    cursor: not-allowed;
  }
}
