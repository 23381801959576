#map-box-container {
  position: relative;
  animation: moveFromLeft 0.2s;
}

#map-search-box-container {
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
  width: 320px;
  margin: auto;
}

@keyframes moveFromLeft {
  from { transform: translateX(-10%); }
  to { transform: translateX(0%); }
}

@keyframes moveFromRight {
  from { transform: translateX(10%); }
  to { transform: translateX(0%); }
}
