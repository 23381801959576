.mapboxPopup {
  color: var(--m-Neutral);
  font-size: 14px;

  :global(.mapboxgl-popup-content) {
    background: var(--menuColor);
    border-color: 1px solid var(--borderColor);
    padding: 10px;
  }

  :global(.mapboxgl-popup-tip) {
    border-top-color: var(--menuColor);
    border-left-color: var(--menuColor);
    border-bottom-color: var(--menuColor);
  }
}
