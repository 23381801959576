.container {
  position: relative;
  height: calc(100vh - var(--headerHeight));

  .demoPanel {
    z-index: 2;
  }

  :global(.mapboxgl-ctrl-logo) {
    display: none;
  }
}
